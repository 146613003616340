import React from "react"
import Layout from "@components/layout"
import { graphql, Link } from "gatsby"
import SeoComponent from "@components/seo"
import marked from "marked"
import PageHeading from "@components/pageHeading"
import HeadingBG from "@images/pexels-rasik-1416899.jpg"

class BlogTemplate extends React.Component {
  render() {
    const { post } = this.props.data
    console.log("BlogTemplate -> render -> post", post)
    const { previous, next } = this.props.pageContext

    // console.log(previous, next)
    return (
      <Layout>
        <SeoComponent title={post.title} description={post.excerpt} />
        <PageHeading
          bg={
            post.mainImage && post.mainImage.asset && post.mainImage.asset.url
              ? post.mainImage.asset.url
              : HeadingBG
          }
        >
          <div className="container">
            <div className="section-heading text-white text-center">
              <span>Published {post.publishedAt}</span>
              <h1>{post.title}</h1>
            </div>
          </div>
        </PageHeading>
        <div className="py-10 dynamic-page-content-wrapper">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                {post.mainImage &&
                post.mainImage.asset &&
                post.mainImage.asset.url ? (
                  <div className="mb-5 text-center">
                    <img
                      src={post.mainImage.asset.url}
                      alt=""
                      className="img-fluid"
                    />{" "}
                  </div>
                ) : null}
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(post.body || ""),
                  }}
                />
              </div>
            </div>
            <div className="border-top pt-4 mt-5">
              <div className="row">
                <div className="col-md-6 text-md-left">
                  {previous ? (
                    <div className="pt-4 pt-md-0">
                      <span className="font-weight-bold text-uppercase text-muted d-block small">
                        Previous
                      </span>
                      <Link
                        to={`/` + previous.slug.current}
                        className="link-unstyled h5"
                      >
                        {previous.title}
                      </Link>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 text-md-right">
                  {next ? (
                    <div className="pt-4 pt-md-0">
                      <span className="font-weight-bold text-uppercase text-muted d-block small">
                        next
                      </span>
                      <Link
                        to={`/` + next.slug.current}
                        className="link-unstyled h5"
                      >
                        {next.title}
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogTemplate

export const blogQuery = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      mainImage {
        asset {
          url
        }
      }
      publishedAt(formatString: "MMMM DD, YYYY")
      body
      title
    }
  }
`
